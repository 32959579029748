.adm-cascader-view {
  --height: auto;
}
.adm-cascader-view-tabs.adm-tabs {
  --title-font-size: var(--adm-font-size-6);
  --content-padding: none;
}
.adm-cascader-view-header-title {
  max-width: 84px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.adm-cascader-view-content {
  height: var(--height);
  overflow-y: auto;
}
.adm-cascader-view-content > .adm-check-list {
  --border-inner: none;
  --border-bottom: none;
  --border-top: none;
}
.adm-cascader-view-item {
  font-size: var(--adm-font-size-6);
}
.adm-cascader-view-item-active {
  color: var(--adm-color-primary);
}
.adm-cascader-view .adm-list-inner {
  margin-bottom: 0;
}
.adm-cascader-view-skeleton {
  padding: 16px 12px;
}
.adm-cascader-view-skeleton .adm-skeleton {
  margin-bottom: 16px;
  --height: 18px;
  --width: 80%;
  --border-radius: 2px;
}
.adm-cascader-view-skeleton .adm-skeleton.adm-cascader-view-skeleton-line-3 {
  --width: 90%;
}
.adm-cascader-view-skeleton .adm-skeleton.adm-cascader-view-skeleton-line-4 {
  --width: 60%;
}
