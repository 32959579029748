.adm-cascader {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.adm-cascader-header {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
}
.adm-cascader-header-button {
  font-size: var(--adm-font-size-7);
  display: inline-block;
  padding: 4px 4px;
}
.adm-cascader-header-title {
  padding: 4px 4px;
  font-size: var(--adm-font-size-7);
  color: var(--adm-color-text);
  text-align: center;
  flex: 1;
}
.adm-cascader-body {
  flex: auto;
  height: 100%;
  width: 100%;
}
.adm-cascader-body > .adm-cascader-view {
  --height: 310px;
}
