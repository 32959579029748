.adm-stepper {
  --height: 28px;
  --input-width: 44px;
  --input-font-size: var(--adm-font-size-main);
  --input-font-color: var(--adm-color-text);
  --input-background-color: var(--adm-color-fill-content);
  --border-radius: 2px;
  --border: none;
  --border-inner: solid 2px transparent;
  --active-border: var(--border);
  --button-font-size: var(--adm-font-size-7);
  --button-text-color: var(--adm-color-primary);
  --button-background-color: var(--adm-color-fill-content);
  --button-width: var(--height);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: calc(var(--input-width) + 2 * var(--button-width));
  border: var(--border);
  border-radius: var(--border-radius);
}
.adm-stepper-active {
  border: var(--active-border);
}
.adm-stepper-middle {
  flex: 1;
  border-left: var(--border-inner);
  border-right: var(--border-inner);
}
.adm-stepper .adm-stepper-input {
  height: var(--height);
  --background-color: var(--input-background-color);
  --font-size: var(--input-font-size);
  --color: var(--input-font-color);
  --text-align: center;
}
.adm-stepper-minus,
.adm-stepper-plus {
  width: var(--button-width);
  height: var(--height);
  padding: 0;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  font-size: var(--button-font-size);
  --border-width: 0;
}
.adm-stepper-minus:disabled,
.adm-stepper-plus:disabled {
  color: var(--adm-color-weak);
}
.adm-stepper-minus svg,
.adm-stepper-plus svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.adm-stepper-minus {
  border-radius: 0;
}
.adm-stepper-plus {
  border-radius: 0;
}
